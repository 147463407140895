<template>
  <v-container id="widgets" fluid>
    <v-row>
      <v-col cols="12">
        <v-snackbar v-model="error_snackbar" color="primary" :timeout="timeout">
          {{ error_snackbar_text }}

          <template v-slot:action="{ attrs }">
            <v-btn
              color="secondary"
              text
              icon
              v-bind="attrs"
              @click="error_snackbar = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <v-tabs active-class="blue--text" color="blue">
          <v-tabs-slider></v-tabs-slider>
          <v-tab>
            <v-icon>mdi-briefcase</v-icon>
            <span class="pl-3">General</span>
          </v-tab>

          <v-tab>
            <v-icon>mdi-note</v-icon>
            <span class="pl-3">Booking Page</span>
          </v-tab>

          <v-tab-item class="pa-md-12">
            <v-sheet color="white" class="mt-5 mb-16">
              <v-row>
                <v-col>
                  <b
                    ><h2 class="text-center text-md-left">
                      Company Details
                    </h2></b
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <span class="ml-4">Company Name</span>
                  <v-subheader
                    >Company name will be displayed everywhere on the
                    system</v-subheader
                  >
                </v-col>
                <v-col cols="12" md="8" class="px-7">
                  <v-text-field
                    v-model="companyName"
                    placeholder="e.g Harry Kitchen"
                    clearable
                    :error-messages="companyNameErrors"
                    required
                    @input="$v.companyName.$touch()"
                    @blur="$v.companyName.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="4">
                  <span class="ml-4">Logo</span>
                  <v-subheader
                    >Company logo will be displayed on the system</v-subheader
                  >
                </v-col>
                <v-col cols="12" md="8" class="px-7">
                  <img :src="imageUrl" height="150" v-if="imageNames" />
                  <br v-if="!imageNames" />
                  <v-icon @click="pickFile()" v-if="!imageNames"
                    >mdi-plus-box</v-icon
                  >
                  <v-icon @click="deleteLogo(), pickFile()" v-if="imageNames"
                    >mdi-pencil-outline</v-icon
                  >
                  <v-icon @click="deleteLogo()" v-if="imageNames"
                    >mdi-delete-outline</v-icon
                  >
                  <!-- <v-text-field
                    label="Select Image"
                    @click="deleteLogo(), pickFile()"
                    v-model="imageNames"
                    prepend-icon="mdi-file-image-outline"
                    :error-messages="imageNamesErrors"
                    required
                  ></v-text-field> -->
                  <input
                    type="file"
                    style="display: none"
                    ref="image"
                    accept="image/*"
                    @change="onFilePicked"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="4">
                  <span class="ml-4">Email</span>
                  <v-subheader
                    >This email will same as admin login email.
                    <br />(non-editable)</v-subheader
                  >
                </v-col>
                <v-col cols="12" md="8" class="px-7">
                  <v-text-field
                    v-model="email"
                    placeholder="e.g email123@hotmail.com"
                    clearable
                    disabled
                    :error-messages="emailErrors"
                    required
                    @input="$v.email.$touch()"
                    @blur="$v.email.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="4">
                  <span class="ml-4">Phone</span>
                  <v-subheader
                    >This phone will show to customer as company
                    phone</v-subheader
                  >
                </v-col>
                <v-col cols="12" md="8" class="px-7">
                  <!-- <vue-tel-input-vuetify
                    v-model="companyPhone"
                  ></vue-tel-input-vuetify> -->
                  <v-text-field
                    v-model="companyPhone"
                    :error-messages="companyPhoneErrors"
                    required
                    @input="$v.companyPhone.$touch()"
                    @blur="$v.companyPhone.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="4">
                  <span class="ml-4">Industry</span>
                  <v-subheader
                    >This field show the industry of company <br />
                    (non-editable)</v-subheader
                  >
                </v-col>
                <v-col cols="12" md="8" class="px-7">
                  <v-select
                    color="primary"
                    v-model="companyIndustry"
                    :items="categories"
                    hide-details
                    single-line
                    disabled
                  ></v-select>
                </v-col>
              </v-row>

              <!-- <v-row>
                <v-col cols="12" md="4">
                  <span class="ml-4">Address</span>
                  <v-subheader
                    >This address will show is means where you main company
                    located
                  </v-subheader>
                </v-col>
                <v-col cols="12" md="8" class="px-7">
                  <v-textarea
                    clear-icon="mdi-close-circle"
                    v-model="companyAddress"
                    :error-messages="companyAddressErrors"
                    required
                    @input="$v.companyAddress.$touch()"
                    @blur="$v.companyAddress.$touch()"
                  ></v-textarea>
                </v-col>
              </v-row> -->

              <v-row>
                <v-col cols="12" md="4">
                  <span class="ml-4">Pre-reminder</span>
                  <v-subheader>
                    The time that send a reminder to customer before the
                    appoinment time</v-subheader
                  >
                </v-col>
                <v-col cols="12" md="2" class="px-7">
                  <v-text-field
                    v-model="reminder"
                    suffix="min"
                    :error-messages="reminderErrors"
                    required
                    @input="$v.reminder.$touch()"
                    @blur="$v.reminder.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>

              <!-- <v-row>
                <v-col cols="12" md="8" class="px-7">
                  <v-text-field
                    v-model="password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                  ></v-text-field>
                </v-col>
              </v-row> -->
              <v-row class="text-center">
                <v-col>
                  <v-btn
                    color="secondary"
                    class="white--text"
                    @click="updateCompany()"
                  >
                    Save
                  </v-btn>
                </v-col>
              </v-row>
            </v-sheet>
          </v-tab-item>
          <v-tab-item class="pa-md-12">
            <v-sheet color="white" class="mt-5 mb-16">
              <v-row>
                <v-col cols="9" md="4">
                  <span class="ml-4">Background Colour</span>
                  <v-subheader
                    >There is two gradient color for your booking page
                    background
                  </v-subheader>
                </v-col>
                <v-col cols="2" md="8" class="pl-7">
                  <v-swatches
                    v-model="color1"
                    show-fallback
                    swatches="text-advanced"
                  ></v-swatches>
                  <v-spacer></v-spacer>
                  <v-swatches
                    v-model="color2"
                    show-fallback
                    swatches="text-advanced"
                  ></v-swatches>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="9" md="4">
                  <span class="ml-4">Step Button Color</span>
                  <v-subheader
                    >The step button color that will show on the booking page
                    for stepper</v-subheader
                  >
                </v-col>
                <v-col cols="2" md="8" class="pl-7">
                  <v-swatches
                    v-model="stepButtonColor"
                    show-fallback
                    swatches="text-advanced"
                  ></v-swatches>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="9" md="4">
                  <span class="ml-4">Continue Button Color</span>
                  <v-subheader
                    >The continue button color that will show on the booking
                    page for stepper.</v-subheader
                  >
                </v-col>
                <v-col cols="2" md="8" class="pl-7">
                  <v-swatches
                    v-model="continueButtonColor"
                    show-fallback
                    swatches="text-advanced"
                  ></v-swatches>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="9" md="4">
                  <span class="ml-4">Header Text Color</span>
                  <v-subheader
                    >This is the color for the header text on the booking
                    page</v-subheader
                  >
                </v-col>
                <v-col cols="2" md="8" class="pl-7">
                  <v-swatches
                    v-model="headerTextColor"
                    show-fallback
                    swatches="text-advanced"
                  ></v-swatches>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="9" md="4">
                  <span class="ml-4">Description Text Color</span>
                  <v-subheader
                    >This is the color for the description text on the booking
                    page</v-subheader
                  >
                </v-col>
                <v-col cols="2" md="8" class="pl-7">
                  <v-swatches
                    v-model="descriptionTextColor"
                    show-fallback
                    swatches="text-advanced"
                  ></v-swatches>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="4">
                  <span class="ml-4">Header</span>
                  <v-subheader
                    >This is the header text show on your booking
                    page</v-subheader
                  >
                </v-col>
                <v-col cols="12" md="6" class="px-7">
                  <v-text-field
                    v-model="header"
                    placeholder="Book Appointment Now!"
                    :error-messages="headerErrors"
                    required
                    @input="$v.header.$touch()"
                    @blur="$v.header.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="4">
                  <span class="ml-4">Description</span>
                  <v-subheader
                    >This is the descirption text below header show on your
                    booking page</v-subheader
                  >
                </v-col>
                <v-col cols="12" md="6" class="px-7">
                  <v-textarea
                    v-model="description"
                    placeholder="Make an appointment with us now to save your time"
                    :error-messages="descriptionErrors"
                    required
                    @input="$v.description.$touch()"
                    @blur="$v.description.$touch()"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <span class="ml-4">Banner</span>
                  <v-subheader
                    >Upload the banner image for the carousel on you booking
                    page</v-subheader
                  >
                </v-col>
                <v-col cols="12" md="6" class="px-7">
                  <vue-upload-multiple-image
                    @upload-success="uploadImageSuccess"
                    @before-remove="beforeRemove"
                    @limit-exceeded="exceededLimit"
                    @size-exceeded="sizeExceeded"
                    accept="image/gif,image/jpeg,image/jpg"
                    :data-images="bannerShow"
                    idUpload="myIdUpload"
                    editUpload="myIdEdit"
                    dragText="Drag to here"
                    browseText="Browse"
                    :maxImage="4"
                    :maxSize="3072000"
                    :showPrimary="false"
                    :showEdit="false"
                  ></vue-upload-multiple-image>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <span class="ml-4">Page Link</span>
                  <v-subheader
                    >This is the link of your booking page. Recommend to set
                    this field as your company name</v-subheader
                  >
                </v-col>
                <v-col cols="12" md="6" class="px-7">
                  <v-text-field
                    prefix="https://ebooking.today/"
                    placeholder="Please enter the url"
                    v-model="url"
                    solo
                    :error-messages="urlErrors"
                    required
                    @input="$v.url.$touch()"
                    @blur="$v.url.$touch()"
                  >
                    <template v-slot:append>
                      <v-btn
                        depressed
                        tile
                        small
                        color="primary"
                        class="ma-0 white--text"
                        @click="copy"
                      >
                        Copy
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="4">
                  <span class="ml-4">QR code</span>
                  <v-subheader
                    >This is the QR code of the link to your booking
                    page</v-subheader
                  >
                </v-col>
                <v-col cols="12" md="8" class="px-7">
                  <qrcode-vue :value="pageLink" :size="size" level="H" />
                </v-col>
              </v-row>

              <v-row class="text-center pb-12">
                <v-col>
                  <v-btn
                    color="secondary"
                    class="white--text"
                    @click="addBanner()"
                  >
                    Save
                  </v-btn>
                </v-col>
              </v-row>
            </v-sheet>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";
import { BASEURL } from "@/api/baseurl";
import axios from "axios";
import QrcodeVue from "qrcode.vue";
import { validationMixin } from "vuelidate";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
  minValue,
} from "vuelidate/lib/validators";

export default {
  name: "Setting",

  mixins: [validationMixin],

  validations: {
    companyName: { required },
    imageNames: {
      required,
    },
    email: { required, email },
    companyPhone: {
      required,
      numeric,
      minLength: minLength(10),
      maxLength: maxLength(11),
    },
    companyIndustry: { required },
    // companyAddress: { required },
    reminder: {
      required,
      numeric,
      minValue: minValue(5),
    },
    url: {
      required,
      maxLength: maxLength(20),
    },
    header: { required },
    description: { required },
  },

  components: { VSwatches, QrcodeVue, VueUploadMultipleImage },

  data: () => ({
    domain: BASEURL,
    company_id: "",
    categories: ["F&B", "Services"],
    companyName: "",
    email: "",
    companyPhone: "",
    companyIndustry: "",
    companyAddress: "",
    password: "",
    color1: "",
    color2: "",
    stepButtonColor: "",
    continueButtonColor: "",
    headerTextColor: "",
    descriptionTextColor: "",
    showPassword: false,
    error_snackbar: false,
    error_snackbar_text: "",
    timeout: 2000,
    imageNames: "",
    imageUrl: "",
    imageFile: "",
    size: 200,
    emailExist: false,
    urlExist: false,
    reminder: "",
    url: "",
    // fileArray: [],
    banner: [],
    header: "",
    description: "",
    beforeDelete: [],
    companyBanner: "",
  }),
  created() {
    var data = JSON.parse(this.getCookie("login"));
    this.company_id = data.company_id;
    this.getCompany();
    this.getAdmin();

  },
  watch: {
    email() {
      this.checkEmail();
    },

    url() {
      this.checkURL();
    },
  },
  computed: {
    companyNameErrors() {
      const errors = [];
      if (!this.$v.companyName.$dirty) return errors;
      !this.$v.companyName.required && errors.push("Company name is required");
      return errors;
    },
    imageNamesErrors() {
      const errors = [];
      if (!this.$v.imageNames.$dirty) return errors;
      !this.$v.imageNames.required && errors.push("Please upload an image");
      return errors;
    },
    companyPhoneErrors() {
      const errors = [];
      if (!this.$v.companyPhone.$dirty) return errors;
      !this.$v.companyPhone.numeric &&
        errors.push("Phone must consist only number");
      !this.$v.companyPhone.minLength &&
        errors.push("Phone must at least 10 number");
      !this.$v.companyPhone.maxLength &&
        errors.push("Phone maxinmum consist of 11 number");
      !this.$v.companyPhone.required && errors.push("Phone is required");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    // companyIndustryErrors() {
    //   const errors = [];
    //   if (!this.$v.companyIndustry.$dirty) return errors;
    //   !this.$v.companyIndustry.required &&
    //     errors.push("Please select the your company industry");
    //   return errors;
    // },

    reminderErrors() {
      const errors = [];
      if (!this.$v.reminder.$dirty) return errors;
      !this.$v.reminder.numeric &&
        errors.push("reminder must consist only number");
      !this.$v.reminder.minValue && errors.push("reminder  minimum 5 minute");
      !this.$v.reminder.required && errors.push("reminder is required");
      return errors;
    },
    // companyAddressErrors() {
    //   const errors = [];
    //   if (!this.$v.companyAddress.$dirty) return errors;
    //   !this.$v.companyAddress.required &&
    //     errors.push("Company address is required");
    //   return errors;
    // },

    urlErrors() {
      const errors = [];
      if (!this.$v.url.$dirty) return errors;
      !this.$v.url.maxLength && errors.push("maximum 20 character");
      !this.$v.url.required && errors.push("url name is required");
      return errors;
    },

    headerErrors() {
      const errors = [];
      if (!this.$v.header.$dirty) return errors;
      !this.$v.header.required && errors.push("header is required");
      return errors;
    },

    descriptionErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) return errors;
      !this.$v.description.required && errors.push("description is required");
      return errors;
    },
    companyColor() {
      var object = {
        color1: this.color1,
        color2: this.color2,
        stepButtonColor: this.stepButtonColor,
        continueButtonColor: this.continueButtonColor,
        headerTextColor: this.headerTextColor,
        descriptionTextColor: this.descriptionTextColor,
      };
      var color = JSON.stringify(object);

      return color;
    },
    pageLink() {
      return "https://ebooking.today/" + this.url;
    },
    bannerShow() {
      return eval(this.companyBanner);
    },
  },
  methods: {
    onFilePicked(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageNames = files[0].name;
        this.imageFile = files[0];
        if (this.imageFile.size > 200000) {
          this.error_snackbar = true;
          this.error_snackbar_text = "maximum size 200kb";
          this.imageNames = "";
          this.imageUrl = "";
          this.imageFile = "";
          return;
        }
        if (this.imageNames.lastIndexOf(".") <= 0) {
          this.imageNames = "";
          this.imageUrl = "";
          this.imageFile = "";
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.imageUrl = fr.result;
          const params = new URLSearchParams();
          params.append("upload_image", "upload_image");
          params.append("image_name", this.imageNames);
          params.append("image", this.imageUrl);

          axios({
            method: "post",
            url: this.domain + "/company/index.php",
            data: params,
          })
            .then((response) => {
              console.log(response);
              if (response.data.status == "1") {
                this.imageNames = response.data.picture_name;
              } else {
                console.log("failed");
              }
            })
            .catch((error) => {
              console.log(error);
            });
        });
      } else {
        this.imageNames = "";
        this.imageUrl = "";
        this.imageFile = "";
      }
    },

    pickFile() {
      this.$refs.image.click();
    },
    deleteLogo() {
      const params = new URLSearchParams();
      params.append("delete_logo", "done");
      params.append("image_name", this.imageNames);

      axios({
        method: "post",
        url: this.domain + "/company/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.imageNames = "";
          } else {
            console.log("delete failed");
            this.imageNames = "";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    copy() {
      navigator.clipboard.writeText(this.pageLink);
    },
    getCompany() {
      const params = new URLSearchParams();
      params.append("read", "done");
      params.append("company_id", this.company_id);

      axios({
        method: "post",
        url: this.domain + "/company/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.companyName = response.data.company[0].name;
            this.imageNames = response.data.company[0].logo;
            this.imageUrl =
              "https://ebooking.today/merchant_logo/" + this.imageNames;
            // this.imageUrl =
            //   "https://lkmng.com/appointment/logo/" + this.imageNames;
            this.companyIndustry = response.data.company[0].industry;
            this.companyAddress = response.data.company[0].address;
            this.reminder = response.data.company[0].pre_reminder_time;
            this.color1 = JSON.parse(response.data.company[0].color).color1;
            this.color2 = JSON.parse(response.data.company[0].color).color2;
            this.header = response.data.company[0].header;
            this.description = response.data.company[0].description;
            this.description = this.description.replaceAll("<br />","")


            this.beforeDelete = response.data.company[0].banner;
            this.companyBanner = response.data.company[0].banner;
            this.banner = eval(response.data.company[0].banner);
            this.stepButtonColor = JSON.parse(
              response.data.company[0].color
            ).color1;
            this.continueButtonColor = JSON.parse(
              response.data.company[0].color
            ).continueButtonColor;
            this.headerTextColor = JSON.parse(
              response.data.company[0].color
            ).headerTextColor;
            this.descriptionTextColor = JSON.parse(
              response.data.company[0].color
            ).descriptionTextColor;
            this.url = response.data.company[0].url;
          } else {
            console.log("no company found");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAdmin() {
      const params = new URLSearchParams();
      params.append("getAdmin", "done");
      params.append("company_id", this.company_id);

      axios({
        method: "post",
        url: this.domain + "/user/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.email = response.data.user[0].email;
            this.companyPhone = response.data.user[0].contact;
          } else {
            console.log("no admin found");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateCompany() {
      this.$v.$touch();
      if (this.emailExist == true) {
        this.error_snackbar = true;
        this.error_snackbar_text = "Email is existed. Please use an new email";
        return;
      } else if (this.emailExist == false) {
        if (this.$v.imageNames.$invalid) {
          this.error_snackbar = true;
          this.error_snackbar_text = "Please add logo image";
          return;
        }
        if (
          !this.$v.companyName.$invalid &&
          !this.$v.imageNames.$invalid &&
          !this.$v.companyPhone.$invalid &&
          !this.$v.reminder.$invalid
        ) {
          const params = new URLSearchParams();
          params.append("update", "done");
          params.append("company_id", this.company_id);
          params.append("name", this.companyName);
          params.append("logo", this.imageNames);
          params.append("email", this.email);
          params.append("contact", this.companyPhone);
          params.append("industry", this.companyIndustry);
          params.append("address", this.companyAddress);
          params.append("pre_reminder_time", this.reminder);
          axios({
            method: "post",
            url: this.domain + "/user/index.php",
            data: params,
          })
            .then((response) => {
              console.log(response);
              if (response.data.status == "1") {
                this.error_snackbar = true;
                this.error_snackbar_text = "Update Successfully";
                setTimeout(function () {
                  location.reload();
                }, 2050);
              } else {
                this.error_snackbar = true;
                this.error_snackbar_text = "email existed";
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },
    updateColor() {
      this.$v.$touch();

      if (this.urlExist == true) {
        this.error_snackbar = true;
        this.error_snackbar_text = "Url existed, Please use another url name.";
        return;
      } else if (this.urlExist == false) {
        if (!this.$v.url.$invalid && !this.$v.header.$invalid && !this.$v.description.$invalid) {
          const params = new URLSearchParams();
          params.append("update", "done");
          params.append("company_id", this.company_id);
          params.append("color", this.companyColor);
          params.append("header", this.header);
          params.append("description", this.description);
          params.append("banner", this.banner);
          params.append("url", this.url);

          axios({
            method: "post",
            url: this.domain + "/company/index.php",
            data: params,
          })
            .then((response) => {
              console.log(response);
              if (response.data.status == "1") {
                this.error_snackbar = true;
                this.error_snackbar_text = "Update Successfully";
                this.getCompany();
              } else {
                this.error_snackbar = true;
                this.error_snackbar_text = "Update Failed";
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },

    checkEmail() {
      const params = new URLSearchParams();
      params.append("checkEmailByCompany", "done");
      params.append("email", this.email);
      params.append("company_id", this.company_id);
      axios({
        method: "post",
        url: this.domain + "/user/index.php",
        data: params,
      })
        .then((response) => {
          if (response.data.status == "1") {
            this.emailExist = true;
          } else {
            this.emailExist = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    checkURL() {
      const params = new URLSearchParams();
      params.append("checkCompanyURL", "done");
      params.append("url", this.url);
      params.append("company_id", this.company_id);
      axios({
        method: "post",
        url: this.domain + "/company/index.php",
        data: params,
      })
        .then((response) => {
          if (response.data.status == "1") {
            this.urlExist = true;
          } else {
            this.urlExist = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },

    uploadImageSuccess(formData, index, fileList) {
      if (index >= 4) {
        this.error_snackbar = true;
        this.error_snackbar_text = "maximum total 4 picture";
        fileList.length = 4;
      } else {
        this.banner = fileList;
      }
    },
    beforeRemove(index, done, fileList) {
      var r = confirm("Are you really want to remove image?");
      if (r == true) {
        console.log("delete", index, fileList);
        done();
        this.banner = fileList;

        this.deleteBanner();
      } else {
        console.log("remove fail");
      }
    },
    exceededLimit() {
      this.error_snackbar = true;
      this.error_snackbar_text = "only 4 image can be upload at one times";
    },

    sizeExceeded() {
      this.error_snackbar = true;
      this.error_snackbar_text = "image size maximum 2mb";
    },

    addBanner() {
      const params = new URLSearchParams();
      params.append("upload_banner", "done");
      params.append("fileObject", JSON.stringify(this.banner));

      axios({
        method: "post",
        url: this.domain + "/company/index.php",
        data: params,
      })
        .then((response) => {
          if (response.data.status == "1") {
            this.banner = JSON.stringify(response.data.picture_array);
            this.updateColor();
          } else {
            if (this.banner == "" || this.banner == []) {
              this.banner = "";
            } else {
              this.banner = JSON.stringify(this.banner);
            }

            console.log("Do not Upload Banner");
            this.updateColor();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteBanner() {
      const array1 = eval(this.beforeDelete);
      const array2 = this.banner;
      const array3 = array1.filter(
        (entry1) => !array2.some((entry2) => entry1.name === entry2.name)
      );
      const params = new URLSearchParams();
      params.append("delete_banner", "done");
      params.append("fileObject", JSON.stringify(array3));

      axios({
        method: "post",
        url: this.domain + "/company/index.php",
        data: params,
      })
        .then((response) => {
          if (response.data.status == "1") {
            console.log("Delete success");
          } else {
            console.log("Delete Fail");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    
  },
};
</script>
<style>
.v-text-field__details {
  margin-top: 2px;
}
</style>
